import {CSSProperties} from 'react'

export const fourOhFourWrapper: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  margin: 'auto',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '70px'
}

export const pageNotFoundWrapper: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '40px',
  width: '386px'
}

export const pageNotFound1Style: CSSProperties = {
  color: '#1F299C',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: '125%',
  letterSpacing: '2.8px',
  textTransform: 'uppercase'
}

export const pageNotFound2Style: CSSProperties = {
  color: '#222',
  fontSize: '64px',
  fontStyle: 'normal',
  fontWeight: '700',
  lineHeight: '100%',
  marginTop: '12px'
}

export const pageNotFound3Style: CSSProperties = {
  color: '#222',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal',
  marginTop: '20px'
}

export const goBackBtnStyle: CSSProperties = {
  display: 'flex',
  height: '40px',
  padding: '12px 20px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',
  borderRadius: '6px',
  background: '#1F299C',
  width: '198px',
  color: '#FCFCFC',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: '125%',
  marginTop: '40px'
}
