import PageNotFoundLogo from '../../../assets/icons/svg/404.svg'
import Link from 'next/link'
import {
  fourOhFourWrapper,
  pageNotFoundWrapper,
  pageNotFound1Style,
  pageNotFound2Style,
  pageNotFound3Style,
  goBackBtnStyle
} from './pageNotFound.styles.consts'
import {FOUR_OH_FOUR_CONSTS} from './pageNotFound.consts'

const PageNotFoundComponent = () => {
  return (
    <div style={fourOhFourWrapper}>
      <PageNotFoundLogo />
      <div style={pageNotFoundWrapper}>
        <div style={pageNotFound1Style}>
          {FOUR_OH_FOUR_CONSTS.pageNotFound1Msg}
        </div>
        <div style={pageNotFound2Style}>
          {FOUR_OH_FOUR_CONSTS.pageNotFound2Msg}
        </div>
        <div style={pageNotFound3Style}>
          {FOUR_OH_FOUR_CONSTS.pageNotFound3Msg}
        </div>
        <Link
          href='/'
          style={goBackBtnStyle}
          id='go-back-btn'
          role='button'
          type='button'
        >
          {FOUR_OH_FOUR_CONSTS.goBackBtnMsg}
        </Link>
      </div>
    </div>
  )
}

export default PageNotFoundComponent
